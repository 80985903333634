import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4451d079"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex search-condition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_virtual_select = _resolveComponent("virtual-select")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_default_val_date_picker = _resolveComponent("default-val-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_virtual_select, {
      label: `品类：${_ctx.label}`,
      modelValue: _ctx.params.category,
      onChange: _ctx.handleCategoryChange,
      options: _ctx.categoryOptions
    }, null, 8, ["label", "modelValue", "onChange", "options"]),
    _withDirectives(_createElementVNode("div", null, "热销时间周期：", 512), [
      [_vShow, _ctx.currentTab === _ctx.TAB_ENUM.HOT]
    ]),
    _withDirectives(_createVNode(_component_el_select, {
      class: "cycle",
      modelValue: _ctx.params.cycle,
      onChange: _ctx.handleCyleChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.HOT_SELLING_DATE_CYCLE_LIST, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onChange"]), [
      [_vShow, _ctx.currentTab === _ctx.TAB_ENUM.HOT]
    ]),
    _withDirectives(_createVNode(_component_default_val_date_picker, {
      modelValue: _ctx.params.date,
      label: "日期",
      placeholder: "请选择",
      type: _ctx.params.cycle,
      format: "YYYY-MM-DD",
      showValue: _ctx.showDateValue,
      onChange: _ctx.handleDateChange
    }, null, 8, ["modelValue", "type", "showValue", "onChange"]), [
      [_vShow, _ctx.currentTab === _ctx.TAB_ENUM.HOT]
    ])
  ]))
}