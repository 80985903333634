import { ref } from 'vue';
import { isEmpty } from 'lodash-es';

import { IDictionaryItem } from '@/store/dictionary/types';
import {
  getCategoryAttributeOptions,
} from '@/modules/resource-manage/domestic-goods/api';
import { getEnumOptions } from '@/modules/information-manage/shop-manage/api';
import { OPTION_ENUM } from '@/modules/information-manage/shop-manage/constant';
import { IOption } from '../type.d';

export const useCommon = () => {
  // 属性选项
  const categoryAttrOptions = ref<IDictionaryItem[]>([]);
  const getCategoryAttrOptions = async (valueCode: string) => {
    try {
      const res = await getCategoryAttributeOptions({
        valueCode, // 测试：203
      });
      const result:IDictionaryItem[] = [];
      const itemMap: {
        [key: string]: IDictionaryItem;
      } = {};
      if (res && !isEmpty(res.data)) {
        res.data.forEach((item) => {
          const id = item.valueCode;
          const pid = item.valueParentCode;
          if (!itemMap[id]) {
            itemMap[id] = {
              label: '',
              value: '',
              disabled: false,
            };
          }
          const obj: IDictionaryItem = {
            label: item.value,
            value: item.valueCode,
            disabled: false,
          };
          if (itemMap[id].children) {
            obj.children = itemMap[id].children;
          }
          itemMap[id] = obj;
          const treeItem = itemMap[id];
          if (pid === '') {
            result.push(treeItem);
          } else {
            if (!itemMap[pid]) {
              itemMap[pid] = {
                label: '',
                value: '',
                disabled: false,
              };
            }
            if (itemMap[pid]?.children) {
                      itemMap[pid].children?.push(treeItem);
            } else {
              itemMap[pid].children = [treeItem];
            }
          }
        });
      }
      categoryAttrOptions.value = result;
    } catch (error) {
      console.log(error);
    }
  };

  // 品类选项
  const categoryOptions = ref<IOption[]>([]);
  const fetchCategoryOptions = async () => {
    const { data } = await getEnumOptions({ dictType: OPTION_ENUM.CATEGORY_TYPE });
    categoryOptions.value = [
      ...data.map((dict) => {
        return {
          label: dict.dictValue,
          value: dict.dictCode,
        };
      }),
    ];
  };

  return {
    categoryOptions,
    categoryAttrOptions,
    getCategoryAttrOptions,
    fetchCategoryOptions,
  };
};
