import { ref, computed, nextTick, watch, Ref } from 'vue';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { datefuns, commonfuns } from 'cx-utils';

import {
  getDomesticGoods,
} from '@/modules/resource-manage/domestic-goods/api';
import { IAttrsItem } from '@/modules/resource-manage/domestic-goods/api/type.d';
import {
  IGoodsItem,
  ICustomParams,
  IParams,
  ISortParams,
  ISortData,
  ISearchPrams,
  IFilterTagsItem,
  ISearchKeys,
} from '../type.d';
import {
  SEARCH_BASE_SETTING_ENUM,
  SEARCH_SALE_SETTING_ENUM,
  SEARCH_DESIGN_SETTING_ENUM,
  SORT_WORD_LIST,
  HOT_SORT_WORD_LIST,
  RECOMMEND_SORT_WORD_LIST,
  SORT_WORD_ENUM,
  TAB_ENUM,
  HOT_SELLING_DATE_CYCLE_ENUM,
  GOODS_STATE_LIST,
  GOODS_IS_NEW_LIST,
} from '@/modules/resource-manage/domestic-goods/constant';
import { useDictionaryStore } from '@/store/dictionary';
import { CX_CUSTOM_DICTIONARY_KEY } from '@/constant/dictionary';
import { usePagination } from './use-pagination';
import { $filters } from '@/core/plugins/filter';
import { IDictionaryItem, IFlatDictionaryItem } from '@/store/dictionary/types';
import { ORDER_ENUM } from '@/constant/global';
import { trackingExposure } from '@/utils/tracking';

export default (
  categoryAttrs: Ref<IDictionaryItem[]>,
) => {
  // 扁平化字典
  const flatAttrsOptions = computed(() => {
    const deep = (
      arr: IDictionaryItem[],
      result: IFlatDictionaryItem[],
      pIds: string[],
      cIds: string[],
      lastLevelChildIds: string[],
    ) => {
      arr.forEach((v) => {
        const currentChildIds: string[] = [];
        const currentLastLevelChildIds: string[] = [];
        if (v.children && v.children.length) {
          deep(v.children, result, [...pIds, v.value], currentChildIds, currentLastLevelChildIds);
        } else {
          lastLevelChildIds.push(v.value);
        }
        cIds.push(v.value, ...currentChildIds);
        lastLevelChildIds.push(...currentLastLevelChildIds);
        result.push({
          ...v,
          pIds: [...pIds],
          cIds: [...currentChildIds],
          lastLevelChildIds: [...currentLastLevelChildIds],
        });
      });
    };
    const result:IFlatDictionaryItem[] = [];
    deep(categoryAttrs.value, result, [], [], []);
    return result;
  });
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const dictionaryStore = useDictionaryStore();
  const sortConfig = ref<ISortParams>({
    prop: null,
    order: null,
  });
  const total = ref(0);
  const keyword = ref(''); // 搜索关键词
  const keywordValue = ref(''); // 搜索关键词-查询请求
  const baseParams: ICustomParams = {
    channelName: '',
    brandName: [],
    storeName: [],
    spuStatus: '',
    isNew: '',
    style: [],
    shelfDate: [],
    offDate: [],
    categoryClass: [],
    categoryAttr: [],
    salesNum: ['', ''],
    salesNumNear30: ['', ''],
    price: ['', ''],
    collectNum: ['', ''],
    salesNumNear7: ['', ''],
    pattern: [],
    thickness: [],
    fabric: [],
    clothingModel: [],
    forAge: ['', ''],
    yearSeason: [],
    hotSellingDate: ['', ''],
  };
  const FLAT_DESIGN_CATEGORY = computed(() => dictionaryStore.flatCxDictionary(CX_CUSTOM_DICTIONARY_KEY.CATEGORY_SPU));
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const finish = ref(false);
  const goodsList = ref<IGoodsItem[]>([]);
  const sortData = ref<ISortData>({});
  sortData.value[TAB_ENUM.DEFAULT] = SORT_WORD_LIST;
  sortData.value[TAB_ENUM.HOT] = HOT_SORT_WORD_LIST;
  sortData.value[TAB_ENUM.RECOMMED] = RECOMMEND_SORT_WORD_LIST;
  const currentTab = ref<TAB_ENUM>(TAB_ENUM.DEFAULT);
  const otherParams = ref<ISearchPrams>({
    category: '-99',
    cycle: HOT_SELLING_DATE_CYCLE_ENUM.WEEK,
    date: dayjs().subtract(7, 'day').toDate(),
  });
  const formatDate = () => {
    const date = new Date(otherParams.value.date as Date);
    if (otherParams.value.cycle === HOT_SELLING_DATE_CYCLE_ENUM.DATE) {
      return {
        startDate: datefuns.formatTime(date, 'YYYYMMDD'),
        endDate: datefuns.formatTime(date, 'YYYYMMDD'),
      };
    }
    const day = date.getDay();
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - day + 1);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    return {
      startDate: datefuns.formatTime(startDate, 'YYYYMMDD'),
      endDate: datefuns.formatTime(endDate, 'YYYYMMDD'),
    };
  };

  const formatParams = (row: ICustomParams) => {
    const data:IParams = {
      // ...row,
    };
    if (row?.shelfDate?.length === 2) {
      [
        data.shelfDateStart,
        data.shelfDateEnd,
      ] = [
        dayjs(row.shelfDate[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dayjs(row.shelfDate[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ];
      delete row.shelfDate;
    }
    if (row?.offDate?.length === 2) {
      [data.offDateStart, data.offDateEnd] = [
        dayjs(row.offDate[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dayjs(row.offDate[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      ];
      delete row.offDate;
    }
    if (row.salesNum?.length === 2) {
      [data.salesNumStart, data.salesNumEnd] = row.salesNum;
      delete row.salesNum;
    }
    if (row.salesNumNear30?.length === 2) {
      [data.salesNumNear30Start, data.salesNumNear30End] = row.salesNumNear30;
      delete row.salesNumNear30;
    }
    if (row.price?.length === 2) {
      [data.priceStart, data.priceEnd] = row.price;
      delete row.price;
    }
    if (row.collectNum?.length === 2) {
      [data.collectNumStart, data.collectNumEnd] = row.collectNum;
      delete row.collectNum;
    }
    if (row.salesNumNear7?.length === 2) {
      [data.salesNumNear7Start, data.salesNumNear7End] = row.salesNumNear7;
      delete row.salesNumNear7;
    }
    if (row.forAge?.length === 2) {
      [data.forAgeStart, data.forAgeEnd] = row.forAge;
      delete row.forAge;
    }
    if (row.categoryClass?.length) {
      let ids: string[] = [];
      row.categoryClass.forEach((v) => {
        ids.push(...v);
      });
      ids = [...new Set([...ids])];
      const categoryClass1: string[] = [];
      const categoryClass2: string[] = [];
      const categoryClass3: string[] = [];
      ids.forEach((id) => {
        const current = FLAT_DESIGN_CATEGORY.value.find(v => v.value === id);
        if (current) {
          const set = new Set([...current.lastLevelChildIds, ...ids]);
          if (set.size === ids.length) {
            const index = current.pIds.length;
            if (index === 0) {
              categoryClass1.push(id);
            } else if (index === 1) {
              categoryClass2.push(id);
            } else if (index === 2) {
              categoryClass3.push(id);
            }
          }
        }
      });
      data.categoryClass1 = categoryClass1;
      data.categoryClass2 = categoryClass2;
      data.categoryClass3 = categoryClass3;
      delete row.categoryClass;
    }
    if (row.categoryAttr?.length) {
      const ids:IAttrsItem[] = [];
      row.categoryAttr.forEach((attr) => {
        const idx = ids.findIndex(item => item.field === attr[0]);
        if (idx > -1) {
          ids[idx].values.push(attr[1]);
        } else {
          ids.push({
            field: attr[0],
            values: [attr[1]],
          });
        }
      });
      data.attrs = ids;
      delete row.categoryAttr;
    }
    const currentParams = {
      ...row,
      ...data,
    };
    return currentParams;
  };
  const getParams = () => {
    let param = {};
    if (currentTab.value === TAB_ENUM.HOT) {
      param = {
        ...(formatDate()),
        categoryName2: otherParams.value.category,
      };
    } else if (currentTab.value === TAB_ENUM.RECOMMED) {
      param = {
        categoryName2: otherParams.value.category,
      };
    } else {
      param = formatParams(cloneDeep(params.value));
    }
    return param;
  };
  const getList = async (current: number) => {
    try {
      const res = await getDomesticGoods({
        // ...formatParams(cloneDeep(params.value)),
        ...getParams(),
        searchType: currentTab.value,
        pageNum: current,
        pageSize: pageSize.value,
        keyword: keywordValue.value,
        ...sortConfig.value,
      });
      const newList = res.data?.list || [];
      goodsList.value.push(...newList);
      trackingExposure(newList, 'spu', {
        moduleId: currentTab.value,
      });
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= current * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    goodsList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    if (val === sortConfig.value.prop) {
      sortConfig.value.order = Object.values(ORDER_ENUM).find(v => v !== sortConfig.value.order);
    } else {
      sortConfig.value.prop = val;
      sortConfig.value.order = ORDER_ENUM.DESC;
    }
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getDomesticGoods({
            ...getParams(),
            searchType: currentTab.value,
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            keyword: keywordValue.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        goodsList.value.push(...list);
        trackingExposure(list, 'spu', {
          moduleId: currentTab.value,
        });
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });

  const initParams = () => {
    keyword.value = '';
    keywordValue.value = '';
    sortConfig.value = {
      prop: null,
      order: null,
    };
    otherParams.value = {
      category: '-99',
      cycle: HOT_SELLING_DATE_CYCLE_ENUM.WEEK,
      date: dayjs().subtract(7, 'day').toDate(),
    };
  };

  watch(
    () => otherParams.value.category,
    () => {
      reload();
    },
  );
  watch(
    () => otherParams.value.cycle,
    (n) => {
      if (n === HOT_SELLING_DATE_CYCLE_ENUM.DATE) {
        otherParams.value.date = dayjs().subtract(1, 'day').toDate();
      } else {
        otherParams.value.date = dayjs().subtract(7, 'day').toDate();
      }
    },
  );
  watch(
    () => otherParams.value.date,
    () => {
      reload();
    },
  );

  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.BRAND_NAME:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '品牌',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.STORE_NAME:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '店铺',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.SPU_STATUS:
          if (commonfuns.isEmpty(value)) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '商品状态',
            key,
            content: $filters.getEnumLabel(GOODS_STATE_LIST, value as string),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.IS_NEW:
          if (commonfuns.isEmpty(value)) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '是否新品',
            key,
            content: $filters.getEnumLabel(GOODS_IS_NEW_LIST, value as string),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.STYLE:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '风格',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_SALE_SETTING_ENUM.SHELF_DATE:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY.MM.DD'),
              dayjs(value[1]).format('YYYY.MM.DD'),
            ];
            tags.push({
              label: '上架日期',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.OFF_DATE:
          if (value.length === 2) {
            const [
              sDate,
              eDate,
            ] = [
              dayjs(value[0]).format('YYYY.MM.DD'),
              dayjs(value[1]).format('YYYY.MM.DD'),
            ];
            tags.push({
              label: '下架日期',
              key,
              content: `${sDate}-${eDate}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_DESIGN_SETTING_ENUM.CATEGORY: {
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          const text = value.map((item: string[]) => {
            const cates = item.map((id) => {
              const row = FLAT_DESIGN_CATEGORY.value.find(it => it.value === id);
              return row?.label || '';
            });
            return cates.join('|');
          }).join('、');
          tags.push({
            label: '品类',
            key,
            content: text,
          });
          tagSortList.value.add(key);
          break;
        }
        case SEARCH_DESIGN_SETTING_ENUM.ATTRIBUTES: {
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          const text = value.map((item: string[]) => {
            const cates = item.map((id) => {
              const row = flatAttrsOptions.value.find(it => it.value === id);
              return row?.label || '';
            });
            return cates.join('|');
          }).join('、');
          tags.push({
            label: '属性',
            key,
            content: text,
          });
          tagSortList.value.add(key);
          break;
        }
        case SEARCH_SALE_SETTING_ENUM.SALES_NUM:
          if (value.length === 2) {
            const [
              sValue,
              eValue,
            ] = value;
            if (commonfuns.isEmpty(sValue) || commonfuns.isEmpty(eValue)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '总销量',
              key,
              content: `${sValue}-${eValue}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.PRICE:
          if (value.length === 2) {
            const [
              sValue,
              eValue,
            ] = value;
            if (commonfuns.isEmpty(sValue) || commonfuns.isEmpty(eValue)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '价格',
              key,
              content: `${sValue}-${eValue}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.COLLECT_NUM:
          if (value.length === 2) {
            const [
              sValue,
              eValue,
            ] = value;
            if (commonfuns.isEmpty(sValue) || commonfuns.isEmpty(eValue)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '总收藏数',
              key,
              content: `${sValue}-${eValue}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR7:
          if (value.length === 2) {
            const [
              sValue,
              eValue,
            ] = value;
            if (commonfuns.isEmpty(sValue) || commonfuns.isEmpty(eValue)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '近7天销量',
              key,
              content: `${sValue}-${eValue}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR30:
          if (value.length === 2) {
            const [
              sValue,
              eValue,
            ] = value;
            if (commonfuns.isEmpty(sValue) || commonfuns.isEmpty(eValue)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '近30天销量',
              key,
              content: `${sValue}-${eValue}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_DESIGN_SETTING_ENUM.PATTERN:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '图案',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.FABRIC:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '面料材质',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.CLOTHING_MODEL:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '版型',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.FOR_AGE:
          if (value.length === 2) {
            const [
              sValue,
              eValue,
            ] = value;
            if (commonfuns.isEmpty(sValue) || commonfuns.isEmpty(eValue)) {
              tagSortList.value.delete(key);
              return;
            }
            tags.push({
              label: '适用年龄',
              key,
              content: `${sValue}-${eValue}`,
            });
            tagSortList.value.add(key);
          } else {
            tagSortList.value.delete(key);
          }
          break;
        case SEARCH_DESIGN_SETTING_ENUM.THICKNESS:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '厚薄',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_DESIGN_SETTING_ENUM.YEAR_SEASON:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '年份季节',
            key,
            content: value.join('、'),
          });
          tagSortList.value.add(key);
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: SEARCH_BASE_SETTING_ENUM|SEARCH_DESIGN_SETTING_ENUM|SEARCH_SALE_SETTING_ENUM,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.BRAND_NAME:
      case SEARCH_BASE_SETTING_ENUM.STORE_NAME:
      case SEARCH_DESIGN_SETTING_ENUM.STYLE:
      case SEARCH_SALE_SETTING_ENUM.SHELF_DATE:
      case SEARCH_SALE_SETTING_ENUM.OFF_DATE:
      case SEARCH_DESIGN_SETTING_ENUM.ATTRIBUTES:
      case SEARCH_DESIGN_SETTING_ENUM.CLOTHING_MODEL:
      case SEARCH_DESIGN_SETTING_ENUM.PATTERN:
      case SEARCH_DESIGN_SETTING_ENUM.FABRIC:
      case SEARCH_DESIGN_SETTING_ENUM.THICKNESS:
      case SEARCH_DESIGN_SETTING_ENUM.YEAR_SEASON:
        params.value[key] = [];
        break;
      case SEARCH_DESIGN_SETTING_ENUM.CATEGORY:
        params.value[key] = [];
        params.value.categoryAttr = [];
        break;
      case SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR7:
      case SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR30:
      case SEARCH_SALE_SETTING_ENUM.SALES_NUM:
      case SEARCH_DESIGN_SETTING_ENUM.FOR_AGE:
      case SEARCH_SALE_SETTING_ENUM.COLLECT_NUM:
      case SEARCH_SALE_SETTING_ENUM.PRICE:
        params.value[key] = ['', ''];
        break;
      case SEARCH_BASE_SETTING_ENUM.IS_NEW:
      case SEARCH_BASE_SETTING_ENUM.SPU_STATUS:
      case SEARCH_BASE_SETTING_ENUM.CHANNLE_NAME:
        params.value[key] = '';
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    tagSortList.value.clear();
    params.value = cloneDeep(baseParams);
  };
  const handleConfirmKeyword = () => {
    params.value.keyword = keyword.value;
    reload();
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    handleConfirmKeyword,
    sortFiltersTags,
    handleRemoveFilterItem,
    handleReset,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    handleChangeSort,
    sortConfig,
    sortData,
    params,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
    keyword,
    keywordValue,
    otherParams,
    currentTab,
    initParams,
  };
};
