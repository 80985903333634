import { computed, ref, Ref } from 'vue';
import { useDictionaryStore } from '@/store/dictionary';
import {
  CX_CUSTOM_DICTIONARY_KEY,
} from '@/constant/dictionary';
import {
  SEARCH_BASE_SETTING_ENUM,
  SEARCH_SALE_SETTING_ENUM,
  SEARCH_DESIGN_SETTING_ENUM,
  GOODS_STATE_LIST,
  GOODS_IS_NEW_LIST,
} from '@/modules/resource-manage/domestic-goods/constant';
import {
  ICustomParams,
  ISearchConfigItem,
} from '../type.d';
import { IDictionaryItem } from '@/store/dictionary/types';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
  categoryAttrs: Ref<IDictionaryItem[]>,
) => {
  const dictionaryStore = useDictionaryStore();

  const DESIGN_CATEGORY_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.CATEGORY_SPU] || []);
  const STORE_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.STORE_NAME_DOMESTIC] || []);
  const BRAND_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.BRAND_NAME_DOMESTIC] || []);
  const STYLE1_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.STYLE1] || []);
  const PATTERN_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.PATTERN] || []);
  const FABRIC_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.FABRIC] || []);
  const CLOTHING_MODEL_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.CLOTHING_MODEL] || []);
  const THICKNESS_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.THICKNESS] || []);
  const YEAR_SEASON_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.YEAR_SEASON] || []);

  const cascaderConfig = ref({
    multiple: true,
  });

  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_DESIGN_SETTING_ENUM.CATEGORY,
        render: () => {
          return (
            <custom-cascader
              label="品类"
              options={DESIGN_CATEGORY_OPTIONS.value}
              v-model={params.value.categoryClass}
              show-all-levels={false}
              props={cascaderConfig.value}
              onChange={() => {
                params.value.categoryAttr = [];
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.ATTRIBUTES,
        render: () => {
          return (
            <custom-cascader
              label="属性"
              options={categoryAttrs.value}
              v-model={params.value.categoryAttr}
              show-all-levels={false}
              props={cascaderConfig.value}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.BRAND_NAME,
        render: () => {
          return (
            <virtual-select
              label="品牌"
              v-model={params.value.brandName}
              multiple
              options={BRAND_NAME_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.STORE_NAME,
        render: () => {
          return (
            <virtual-select
              label="店铺"
              v-model={params.value.storeName}
              multiple
              options={STORE_NAME_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.STYLE,
        render: () => {
          return (
            <virtual-select
              label="风格"
              v-model={params.value.style}
              multiple
              options={STYLE1_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.SPU_STATUS,
        render: () => {
          return (
            <custom-select
              label="商品状态"
              v-model={params.value.spuStatus}
            >
              {GOODS_STATE_LIST.map(v => (
                <el-option value={v.value} label={v.label} />
              ))}
            </custom-select>
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.IS_NEW,
        render: () => {
          return (
            <custom-select
              label="是否新品"
              v-model={params.value.isNew}
            >
              {GOODS_IS_NEW_LIST.map(v => (
                <el-option value={v.value} label={v.label} />
              ))}
            </custom-select>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR7,
        render: () => {
          return (
            <>
              {params.value?.salesNumNear7?.length && (
                <custom-number-range
                  label="近7天销量"
                  v-models={[
                    [params.value.salesNumNear7[0], 'rangeStart'],
                    [params.value.salesNumNear7[1], 'rangeEnd'],
                  ]}
                  unit=""
                  min={0}
                  max={999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.SALES_NUM_NEAR30,
        render: () => {
          return (
            <>
              {params.value?.salesNumNear30?.length && (
                <custom-number-range
                  label="近30天销量"
                  v-models={[
                    [params.value.salesNumNear30[0], 'rangeStart'],
                    [params.value.salesNumNear30[1], 'rangeEnd'],
                  ]}
                  unit=""
                  min={0}
                  max={999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.SALES_NUM,
        render: () => {
          return (
            <>
              {params.value?.salesNum?.length && (
                <custom-number-range
                  label="总销量"
                  v-models={[
                    [params.value.salesNum[0], 'rangeStart'],
                    [params.value.salesNum[1], 'rangeEnd'],
                  ]}
                  min={0}
                  max={999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      // {
      //   label: '渠道名称',
      //   key: SEARCH_BASE_SETTING_ENUM.CHANNLE_NAME,
      //   render: () => {
      //     return (
      //       <el-input
      //         v-model={[params.value.channelName, ['trim']]}
      //         placeholder="模糊搜索"
      //         clearable
      //         class="align_right"
      //       />
      //     );
      //   },
      // },
      // {
      //   label: '店铺',
      //   key: SEARCH_BASE_SETTING_ENUM.STORE_NAME,
      //   render: () => {
      //     return (
      //       <custom-select
      //         label="店铺"
      //         v-model={params.value.storeName}
      //         multiple
      //       >
      //         {STORE_NAME_OPTIONS.value.map(v => (
      //           <el-option value={v.label} label={v.label} />
      //         ))}
      //       </custom-select>
      //     );
      //   },
      // },
    ];
    if (categoryAttrs.value.length === 0) {
      const index = baseConfig.findIndex(v => v.key === SEARCH_DESIGN_SETTING_ENUM.ATTRIBUTES);
      baseConfig.splice(index, 1);
    }
    return baseConfig;
  });
  const advancedSearchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_DESIGN_SETTING_ENUM.CLOTHING_MODEL,
        render: () => {
          return (
            <virtual-select
              label="版型"
              v-model={params.value.clothingModel}
              multiple
              options={CLOTHING_MODEL_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.FABRIC,
        render: () => {
          return (
            <virtual-select
              label="面料材质"
              v-model={params.value.fabric}
              multiple
              options={FABRIC_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.THICKNESS,
        render: () => {
          return (
            <virtual-select
              label="厚薄"
              v-model={params.value.thickness}
              multiple
              options={THICKNESS_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.PATTERN,
        render: () => {
          return (
            <virtual-select
              label="图案"
              v-model={params.value.pattern}
              multiple
              options={PATTERN_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.FOR_AGE,
        render: () => {
          return (
            <>
              {params.value?.forAge?.length && (
                <custom-number-range
                  label="适用年龄"
                  v-models={[
                    [params.value.forAge[0], 'rangeStart'],
                    [params.value.forAge[1], 'rangeEnd'],
                  ]}
                  min={0}
                  max={99}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      {
        key: SEARCH_DESIGN_SETTING_ENUM.YEAR_SEASON,
        render: () => {
          return (
            <virtual-select
              label="年份季节"
              v-model={params.value.yearSeason}
              multiple
              options={YEAR_SEASON_OPTIONS.value}
              prop={{
                value: 'label',
              }}
            />
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.COLLECT_NUM,
        render: () => {
          return (
            <>
              {params.value?.collectNum?.length && (
                <custom-number-range
                  label="总收藏数"
                  v-models={[
                    [params.value.collectNum[0], 'rangeStart'],
                    [params.value.collectNum[1], 'rangeEnd'],
                  ]}
                  unit=""
                  min={0}
                  max={999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.SHELF_DATE,
        render: () => {
          return (
            <custom-date-picker
              label="上架日期"
              v-model={params.value.shelfDate}
            />
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.OFF_DATE,
        render: () => {
          return (
            <custom-date-picker
              label="下架日期"
              v-model={params.value.offDate}
            />
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.PRICE,
        render: () => {
          return (
            <>
              {params.value.price?.length && (
                <custom-number-range
                  label="价格"
                  v-models={[
                    [params.value.price[0], 'rangeStart'],
                    [params.value.price[1], 'rangeEnd'],
                  ]}
                  unit="元"
                  min={0}
                  max={999999}
                  precision={2}
                  rule={[{
                    validator: (_rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s === '' || e === '') {
                          cb('请填写完整的区间');
                        } else if (s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
    ];
    return baseConfig;
  });
  const getOptions = () => {
    dictionaryStore.getCxDictionary([
      CX_CUSTOM_DICTIONARY_KEY.STORE_NAME_DOMESTIC,
      CX_CUSTOM_DICTIONARY_KEY.CATEGORY_SPU,
      CX_CUSTOM_DICTIONARY_KEY.STYLE1,
      CX_CUSTOM_DICTIONARY_KEY.PATTERN,
      CX_CUSTOM_DICTIONARY_KEY.FABRIC,
      CX_CUSTOM_DICTIONARY_KEY.CLOTHING_MODEL,
      CX_CUSTOM_DICTIONARY_KEY.THICKNESS,
      CX_CUSTOM_DICTIONARY_KEY.YEAR_SEASON,
      CX_CUSTOM_DICTIONARY_KEY.BRAND_NAME_DOMESTIC,
    ]);
  };
  return {
    searchConfig,
    advancedSearchConfig,
    getOptions,
  };
};
