
import { defineComponent, PropType, computed } from 'vue';
import { cloneDeep } from 'lodash-es';
import { datefuns } from 'cx-utils';
import dayjs from 'dayjs';

import DefaultValDatePicker from '../default-val-date-picker/index.vue';

import {
  HOT_SELLING_DATE_CYCLE_ENUM,
  HOT_SELLING_DATE_CYCLE_LIST,
  TAB_ENUM,
} from '@/modules/resource-manage/domestic-goods/constant';
import { ISearchPrams } from '../../type.d';

export default defineComponent({
  components: {
    DefaultValDatePicker,
  },

  props: {
    params: {
      type: Object as PropType<ISearchPrams>,
      default: () => ({
        category: '',
        cycle: HOT_SELLING_DATE_CYCLE_ENUM.WEEK,
        date: dayjs().subtract(7, 'day').toDate(),
      }),
    },
    currentTab: {
      type: String,
      default: TAB_ENUM.DEFAULT,
    },
    categoryOptions: {
      type: Array as PropType<{ label: string; value: string; }[]>,
      default: () => [],
    },
  },

  emits: ['update:params'],

  setup(props, { emit }) {
    const showDateValue = computed(() => {
      const date = new Date(props.params.date.getTime());
      let str: string[] = [''];
      if (props.params.cycle === HOT_SELLING_DATE_CYCLE_ENUM.DATE) {
        str = [datefuns.formatTime(date, 'YYYY-MM-DD')];
      } else {
        const day = date.getDay();
        const startDate = new Date(date);
        startDate.setDate(startDate.getDate() - day + 1);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        str = [datefuns.formatTime(startDate, 'YYYY-MM-DD'), datefuns.formatTime(endDate, 'YYYY-MM-DD')];
      }
      return str;
    });

    const label = computed(() => {
      const obj = props.categoryOptions.find(item => item.value === props.params.category);
      return obj?.label || '';
    });

    const handleUpdate = (obj: {[key: string]: any; }) => {
      let params = cloneDeep(props.params);
      params = {
        ...params,
        ...obj,
      };
      emit('update:params', params);
    };

    const handleCategoryChange = (category: string) => {
      handleUpdate({ category });
    };

    const handleCyleChange = (cycle: HOT_SELLING_DATE_CYCLE_ENUM) => {
      handleUpdate({ cycle });
    };

    const handleDateChange = (date: any) => {
      handleUpdate({ date });
    };

    return {
      HOT_SELLING_DATE_CYCLE_LIST,
      TAB_ENUM,
      showDateValue,
      label,
      handleCategoryChange,
      handleCyleChange,
      handleDateChange,
    };
  },
});

