
import { defineComponent, ref, withKeys } from 'vue';

import Tabs from '@/components/tabs';

import { TAB_ENUM, TAB_LIST } from '@/modules/resource-manage/domestic-goods/constant';

export default defineComponent({
  components: {
    Tabs,
  },

  props: {
    keyword: {
      type: String,
      default: '',
    },
  },

  emits: ['update:keyword', 'search', 'change'],

  setup(_, { emit }) {
    const currentTab = ref<TAB_ENUM>(TAB_ENUM.DEFAULT);

    // 切换 Tab
    const handleTab = () => {
      emit('change', currentTab.value);
    };

    const handleInput = (v: string) => {
      emit('update:keyword', v);
    };

    const hanldeSearch = () => {
      emit('search');
    };

    const handleConfirmKeyword = () => {
      emit('search');
    };

    return {
      currentTab,
      handleTab,
      handleInput,
      hanldeSearch,
      handleConfirmKeyword,
    };
  },

  render() {
    return (
      <el-row class="tab-bar" justify="space-between" align="middle">
        <el-col span={16}>
          <tabs
            lineHeight={56}
            fontSize={16}
            border={false}
            config={TAB_LIST}
            v-model={this.currentTab}
            onChange={this.handleTab}
          />
        </el-col>
        <el-col span={8}>
          <el-input
            modelValue={this.keyword}
            placeholder="请输入商品名称进行搜索"
            input-style={{
              paddingRight: '36px',
              backgroundColor: '#EFF0F5',
            }}
            v-slots={{
              suffix: () => (
                  <i class="iconfont icon_icon_search tab-bar_search_icon" onClick={this.hanldeSearch} />
              ),
            }}
            onInput={this.handleInput}
            onKeydown={withKeys(this.handleConfirmKeyword, ['enter'])}
          />
        </el-col>
      </el-row>
    );
  },
});
