
import { defineComponent, ref, nextTick, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

import SelectImgDialog from '@/components/select-img-dialog/index.vue';
import Goods from './components/goods.vue';
import TabBar from './components/tab-bar/index.vue';
import SearchCondition from './components/search-condition/index.vue';

import {
  getDesignTaskOptions,
} from '@/modules/task-manage/design-task/api/index';
import {
  IGetDesignTaskOptionsRes,
} from '@/modules/task-manage/design-task/api/types';
import {
  confirmImageBySpu,
} from '@/modules/resource-manage/domestic-goods/api';
import { GOOD_TYPE_ENUM } from '@/components/select-img-dialog/constant';
import { processRouterQueryParams } from '@/utils/';
import { useSearchConfig } from './composables/use-search-config';
import useGoodsList from './composables/use-goods-list';
import { useCommon } from './composables/use-common';
import {
  TAB_ENUM,
} from '@/modules/resource-manage/domestic-goods/constant';
import {
  ISearchSettingConfig,
  IGoodsItem,
} from './type.d';
import { CHANNEL_ENUM, ORDER_ENUM } from '@/constant/global';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Goods,
    SelectImgDialog,
    TabBar,
    SearchCondition,
  },
  setup() {
    const $router = useRouter();
    const goodsEl = ref<null|HTMLElement>(null);
    const searchSettingConfig = ref<ISearchSettingConfig>({
      visible: false,
    });
    /**
     * 特殊属性
     */
    const {
      categoryAttrOptions,
      getCategoryAttrOptions,
      categoryOptions,
      fetchCategoryOptions,
    } = useCommon();
    // 列表查询
    const {
      handleCurrentSizeChange,
      params,
      goodsList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
      keyword,
      keywordValue,
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      sortData,
      otherParams,
      currentTab,
      initParams,
    } = useGoodsList(categoryAttrOptions);
    const {
      searchConfig,
      advancedSearchConfig,
      getOptions,
    } = useSearchConfig(params, categoryAttrOptions);
    getOptions();
    watch(() => params.value.categoryClass, (n) => {
      if (n && n.length === 1) {
        const ary = n[0];
        getCategoryAttrOptions(n[0][ary.length - 1]);
        return;
      }
      categoryAttrOptions.value = [];
    });
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();
    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      initPagination(goodsEl.value!);
      params.value = urlParams;
    };
    onMounted(() => {
      init();
      fetchCategoryOptions();
    });

    const selectImgDialogConfig = ref({
      visible: false,
      urls: [] as string[],
      categoryClass1: '',
      spu: '',
    });
    const detailConfig = ref({
      visible: false,
      data: {
        channelId: '',
        skc: '',
      },
    });
    const handleSelectImgItem = async (url: string, category: string, type: 'quick' | 'normal', spu: string) => {
      const id = localStorage.getItem('quick_design_id');
      let eventDetails: { [key: string]: string; } = {
        eventName: '选图',
        goodId: spu || '',
        moduleId: currentTab.value,
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (type === 'quick' && id) {
        await confirmImageBySpu({
          id,
          selectedImg: url,
          categoryClass1: category,
        });
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgDialogConfig.value.spu = spu;
      selectImgDialogConfig.value.urls = [url];
      selectImgDialogConfig.value.visible = true;
      selectImgDialogConfig.value.categoryClass1 = category;
    };
    const hanldeSearch = async () => {
      keywordValue.value = keyword.value;
      reload();
    };
    // 点击图片
    const handleGoodItem = (row: IGoodsItem) => {
      trackingClick({
        eventName: '查看商品详情',
        goodId: row.spu,
        moduleId: currentTab.value,
      });
      const newPage = $router.resolve({
        name: 'DomesticGoodsDetail',
        params: {
          spu: row.spu,
        },
      });
      window.open(newPage.href);
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: selectImgDialogConfig.value.spu,
        moduleId: currentTab.value,
        taskId: id,
      });
    };

    // 找相似
    const handleSearch = (row: IGoodsItem) => {
      trackingClick({
        eventName: '找相似',
        goodId: row.spu,
        moduleId: currentTab.value,
      });
      const newPage = $router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(row.spuPictureLink),
          channelId: CHANNEL_ENUM.TAOPIN,
        },
      });
      window.open(newPage.href);
    };

    // 切换 TAB
    const handleTab = (v: TAB_ENUM) => {
      initParams();
      currentTab.value = v;
      handleReset();
    };

    return {
      advancedSearchConfig,
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      categoryAttrOptions,
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      hanldeSearch,
      handleChangeSort,
      sortConfig,
      searchConfig,
      searchSettingConfig,
      designTaskOptions,
      handleSelectImgItem,
      selectImgDialogConfig,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      useSearchConfig,
      detailConfig,
      keyword,
      sortData,
      currentTab,
      categoryOptions,
      otherParams,
      handleGoodItem,
      handleSelectSuccess,
      handleSearch,
      handleTab,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <tab-bar
            v-model={[this.keyword, 'keyword']}
            onSearch={this.hanldeSearch}
            onChange={this.handleTab}
          />
          {this.currentTab === TAB_ENUM.DEFAULT && <filter-bar
            tags={this.sortFiltersTags}
            searchConfig={this.searchConfig}
            advancedSearchConfig={this.advancedSearchConfig}
            onReset={this.handleReset}
            onRemoveItem={this.handleRemoveFilterItem}
          />}
          {this.currentTab !== TAB_ENUM.DEFAULT && <search-condition
            v-model={[this.otherParams, 'params']}
            currentTab={this.currentTab}
            categoryOptions={this.categoryOptions}
          />}
          <sort-bar
            sortList={this.sortData[this.currentTab]}
            ascKey={ORDER_ENUM.ASC}
            descKey={ORDER_ENUM.DESC}
            prop={this.sortConfig.prop}
            order={this.sortConfig.order}
            onHandleItem={this.handleChangeSort}
          />
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <goods
                  key={i}
                  data={v}
                  currentTab={this.currentTab}
                  onSelectImg={this.handleSelectImgItem}
                  onImage={this.handleGoodItem}
                  onSearch={this.handleSearch}
                ></goods>
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgDialogConfig.visible, 'visible']}
          urls={this.selectImgDialogConfig.urls}
          categoryClass1={this.selectImgDialogConfig.categoryClass1}
          options={this.designTaskOptions}
          goodType={GOOD_TYPE_ENUM.SPU}
          onSuccess={this.handleSelectSuccess}
        />
      </div>
    );
  },
});
