
import { defineComponent, PropType, computed } from 'vue';
import dayjs from 'dayjs';

import { formatMillion } from '@/utils/helper';
import { IGoodsItem } from '../type.d';
import {
  INSPIRATION_IMG_TYPE_ENUM,
} from '@/modules/task-manage/design-task/constant';

import { GOODS_STATE_ENUM, GOODS_STATE_LIST, TAB_ENUM } from '@/modules/resource-manage/domestic-goods/constant';
import usePermissionConfig from '../../../use-permission-config';

export default defineComponent({
  emits: ['select-img', 'image', 'search'],
  props: {
    data: {
      type: Object as PropType<IGoodsItem>,
      default: () => ({}),
    },
    currentTab: {
      type: String,
      default: TAB_ENUM.DEFAULT,
    },
  },
  setup(props, { emit }) {
    const permissionConfig = usePermissionConfig();
    const handleSelectImgItem = (row: IGoodsItem, type: 'quick'|'normal' = 'normal') => {
      emit(
        'select-img',
        `0/-${row.spu}/-${row.channelId}/-${INSPIRATION_IMG_TYPE_ENUM.STYLE}`,
        row.categoryClass2Name || row.categoryClass1Name,
        type,
        row.spu,
      );
    };
    const handleImageItem = (row: IGoodsItem) => {
      emit('image', row);
    };
    const shelfDate = computed(() => {
      return props.data.latelyShelfDate ? dayjs(props.data.latelyShelfDate).format('YYYY-MM-DD') : '';
    });

    const handleSearch = (row: IGoodsItem) => {
      emit('search', row);
    };

    return {
      TAB_ENUM,
      permissionConfig,
      handleImageItem,
      GOODS_STATE_ENUM,
      GOODS_STATE_LIST,
      handleSelectImgItem,
      shelfDate,
      handleSearch,
      formatMillion,
    };
  },
  components: {},
});
