import { debounce } from 'lodash-es';
import { ref, nextTick, onBeforeUnmount } from 'vue';

export const usePagination = () => {
  const itemConfig = {
    width: 291 + 6,
    height: 549,
    header: 265,
    banner: 59,
  };
  const maxColumn = ref(3); // 行数
  const pageSize = ref(10);
  // 当前请求的页数
  const reqPageIndex = ref(1);
  // 当前视图聚焦页数
  const currentViewPage = ref(1);
  const handleScrollToPage = async (val: number) => {
    await nextTick();
    const top = (val - 1) * itemConfig.height * maxColumn.value + 2 + itemConfig.header;
    window.scrollTo(0, top);
  };
  const scrollListener = debounce(() => {
    const page = Math.ceil((window.pageYOffset - itemConfig.header) / (itemConfig.height * maxColumn.value)) || 1;
    currentViewPage.value = page;
  }, 50);
  const removeListener = () => {
    window.removeEventListener('scroll', scrollListener);
  };
  const createListener = () => {
    removeListener();
    window.addEventListener('scroll', scrollListener);
  };
  const initPagination = async (dom: HTMLElement) => {
    const { width } = dom.getBoundingClientRect();
    dom.setAttribute('style', `width:${width}px`);
    // 单屏最多行数
    const column = Math.ceil((+window.innerHeight - itemConfig.banner - itemConfig.header) / itemConfig.height) + 1;
    if (column > maxColumn.value) {
      maxColumn.value = column;
    }
    // 单行最多条数
    const lineCount = Math.floor(width / itemConfig.width);
    pageSize.value = lineCount * maxColumn.value;
    createListener();
  };
  onBeforeUnmount(() => {
    removeListener();
  });
  return {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  };
};
